import { FC, useState } from 'react'
import { ActionItem } from '../../../types/actionItem';
import { ActionCardHeaderStyles, ActionModuleDataHeader, ActionModuleSingleData, MaxUserWrapper } from '../ActionsBlock.styles';
import ActionButton from '../../../components/common/table/ActionButton';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { NumberInput } from '../../../components/common/table/NumberInput';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../hooks/StoreHooks';
import { setEditedActionData } from '../../../store/reducers/actionsReducer';
import { editActionThunk } from '../../../store/thunk/actionsThunk';

export const ActionEdit: FC<{
    actionItem: ActionItem;
}> = ({ actionItem }) => {
    const {
        name,
        description,
        iconName,
        colorHex,
        frequency,
        recurring,
        id,
        maxPoints,
        employeeDescription,
        rewardedPointsFullyFullfilled,
        maxUserCanComplete
    } = actionItem

    const [actionItemState, setActionItemState] = useState<Partial<ActionItem>>({
        id,
        name,
        description,
        employeeDescription,
        rewardedPointsFullyFullfilled,
        maxUserCanComplete
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isLimitDisabled, setIsLimitDisabled] = useState(maxUserCanComplete == null);

    const handleEdit = async () => {
        let updateInfo: Partial<ActionItem> = {
            id: actionItemState.id,
            name: actionItemState.name,
            description: actionItemState.description,
            employeeDescription: actionItemState.employeeDescription,
            rewardedPointsFullyFullfilled: actionItemState.rewardedPointsFullyFullfilled,
            rewardedPointsPartiallyFullfilled: Math.round(actionItemState.rewardedPointsFullyFullfilled! / 2),
            rewardedPointsNotFullfilled: 0,
            maxUserCanComplete: actionItemState.maxUserCanComplete
        }
        dispatch(setEditedActionData(updateInfo));
        await dispatch(editActionThunk())
    }

    return (
        <Box>
            <ActionCardHeaderStyles>
                <ActionButton
                    iconName={iconName}
                    colorHex={colorHex ? colorHex : ""}
                    actionName={name}
                />
                <Typography variant="h5">
                    {name}
                </Typography>
            </ActionCardHeaderStyles>
            <ActionModuleSingleData>
                <ActionModuleDataHeader>{t("actionPage.modulName")}</ActionModuleDataHeader>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={actionItemState.name}
                    onChange={(v) =>
                        setActionItemState({ ...actionItemState, name: v.target.value })}
                />
            </ActionModuleSingleData>
            <ActionModuleSingleData>
                <ActionModuleDataHeader>{t("actionPage.modulDescription")}</ActionModuleDataHeader>
                <TextField
                    fullWidth
                    value={actionItemState.description}
                    multiline
                    maxRows={3}
                    onChange={(v) =>
                        setActionItemState({ ...actionItemState, description: v.target.value })} />
            </ActionModuleSingleData>
            <ActionModuleSingleData>
                <ActionModuleDataHeader>{t("actionPage.infoText")}</ActionModuleDataHeader>
                <TextField
                    fullWidth
                    value={actionItemState.employeeDescription}
                    multiline
                    maxRows={3}
                    onChange={(v) =>
                        setActionItemState({ ...actionItemState, employeeDescription: v.target.value })}
                />
            </ActionModuleSingleData>
            <ActionModuleSingleData>
                <ActionModuleDataHeader>{t("actionPage.reachablePoints")}</ActionModuleDataHeader>
                <NumberInput
                    min={0}
                    value={actionItemState.rewardedPointsFullyFullfilled}
                    style={{ width: "50%" }}
                    onChange={(e, val) =>
                        setActionItemState({
                            ...actionItemState, rewardedPointsFullyFullfilled: val ? val : 0
                        })}
                />
            </ActionModuleSingleData>
            <ActionModuleSingleData>
                <ActionModuleDataHeader>{t("actionPage.maxUsers")}</ActionModuleDataHeader>
                <MaxUserWrapper>
                    <FormControlLabel
                        control={<Checkbox checked={isLimitDisabled} />}
                        label={t("actionPage.noLimit")}
                        onChange={(e, val) => {
                            setIsLimitDisabled(val)
                            setActionItemState({
                                ...actionItemState,
                                maxUserCanComplete: val ? null : actionItemState.maxUserCanComplete
                            })
                        }}
                    />
                    <NumberInput
                        min={1}
                        value={isLimitDisabled ? null : actionItemState.maxUserCanComplete ? actionItemState.maxUserCanComplete : 1}
                        disabled={isLimitDisabled}
                        style={{ width: "60%" }}
                        onChange={(e, val) =>
                            setActionItemState({
                                ...actionItemState, maxUserCanComplete: val
                            })}
                    />
                </MaxUserWrapper>
            </ActionModuleSingleData>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <ActionModuleSingleData>
                            <ActionModuleDataHeader>{t("actionPage.frequency")}</ActionModuleDataHeader>
                            <Typography>{t(`frequency.${frequency.toLowerCase()}`).substring(0, 1).toUpperCase() + t(`frequency.${frequency.toLowerCase()}`).substring(1)}</Typography>
                        </ActionModuleSingleData>
                    </Grid>
                    <Grid item xs={4}>
                        <ActionModuleSingleData>
                            <ActionModuleDataHeader>{t("actionPage.recurrence")}</ActionModuleDataHeader>
                            <Typography>{t(`actionModulesPage.actionModules.${recurring.toLowerCase()}`)}</Typography>
                        </ActionModuleSingleData>
                    </Grid>
                    <Grid item xs={4}>
                        <ActionModuleSingleData>
                            <ActionModuleDataHeader>{t("actionPage.maxPoints")}</ActionModuleDataHeader>
                            <Typography>{maxPoints}</Typography>
                        </ActionModuleSingleData>
                    </Grid>
                </Grid>
            </Box>
            <Button
                variant="contained"
                onClick={handleEdit}
                sx={{ mt: 3, marginTop: "2em" }}
            >
                {t("buttons.update")}
            </Button>
        </Box>
    )
}