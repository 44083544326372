import { useEffect, useState } from "react";
import { ActionModuleManagementStyles, SearchBarWrapper } from "../ActionModuleManagement.styles";
import { actionsApi } from "../../../api/actionsApi";
import { PaginationParameters } from "../../../api/Pagination";
import IncentivationOverviewTable from "./IncentivationOverviewTable";
import ServerSideSearchBar from "../../../components/common/table/ServerSideSearchBar";
import EmployeeIncentivationType from "../../../types/employeeIncentivationType";
import { setProcessingRequest } from "../../../store/reducers/tableManagementReducer";
import { useAppDispatch } from "../../../hooks/StoreHooks";

interface Props {
    actionModuleSubtype: string;
}

export function ActionModuleIncentivationOverview(props: Props) {
    const dispatch = useAppDispatch();
    const [tableContent, setTableContent] = useState<EmployeeIncentivationType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [paginationParameters, setPaginationParameters] = useState<PaginationParameters>({
        limit: 10,
        offset: 0
    });

    useEffect(() => {
        const fetchUsers = async () => {
            setTableContent([]);
            dispatch(setProcessingRequest(true));
            const res = await actionsApi.getEmployeesForSubtype(
                props.actionModuleSubtype,
                paginationParameters
            );
            dispatch(setProcessingRequest(false));
            setTableContent(res?.result?.items ?? []);
            setTotalCount(res?.result?.totalCount ?? 0);

        };
        fetchUsers();
    }, [props.actionModuleSubtype, paginationParameters]);

    return <ActionModuleManagementStyles>
        <SearchBarWrapper>
            <ServerSideSearchBar onSearchHandler={(s: string) => setPaginationParameters({
                ...paginationParameters,
                search: s,
            })} />
        </SearchBarWrapper>

        <IncentivationOverviewTable
            totalCount={totalCount}
            pagination={paginationParameters}
            onUpdatePagination={setPaginationParameters}
            employees={tableContent}
        />
    </ActionModuleManagementStyles>
}