import AccountManagementTableHeader from "./AccountManagementTableHeader";
import { FC, useEffect } from "react";
import AccountManagementTableBody from "./AccountManagementTableBody";
import {
  MainContentWrapper,
  TableWrapper,
} from "../../../components/common/Common.styles";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { fetchGroupsThunk } from "../../../store/thunk/groupsThunk";
interface AccountManagementTableProps {
  searchQuery: string;
}

const AccountManagementTable: FC<AccountManagementTableProps> = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        await dispatch(fetchGroupsThunk());
      } catch (error) {
        console.log("error")
      }
    };
    fetchCompanies();
  }, []);

  return (
    <MainContentWrapper>
      <TableWrapper
        columnsTemplate="1.25fr 1.25fr 1.17fr 1fr 1fr 1fr 1fr 1fr 1fr minmax(175px,auto)"
        minWidth="1330px"
      >
        <AccountManagementTableHeader />
        <AccountManagementTableBody searchQuery={searchQuery} />
      </TableWrapper>
    </MainContentWrapper>
  );
};

export default AccountManagementTable;
