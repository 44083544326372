class KeyValueStore {
    setBoolean(key: string, value: boolean) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getBoolean(key: string, defaultValue: boolean = false): boolean {
        const strValue = localStorage.getItem(key);

        if (strValue != null) {
            return JSON.parse(strValue);
        }

        return defaultValue;
    }

    setObject<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getObject<T>(key: string, defaultValue: T) {
        const strValue = localStorage.getItem(key);

        if (strValue != null) {
            return JSON.parse(strValue);
        }

        return defaultValue;
    }
}

export const keyValueStorage = new KeyValueStore();
