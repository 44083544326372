import { IconButton } from "@mui/material";
import { EditIcon } from "../../../assets/icons/Icons";
import { FC } from "react";

interface EditBtnProps {
  handleOnClick: () => void;
}

const EditBtn: FC<EditBtnProps> = ({ handleOnClick }) => {
  return (
    <IconButton
      onClick={handleOnClick}
      sx={{
      }}>
      <EditIcon />
    </IconButton>
  );
};

export default EditBtn;
