import { Box, Button, Typography } from "@mui/material";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import CellBox from "../../../components/common/table/CellBox";
import { useEffect, useState } from "react";
import { TableColumnHeaderWrapper } from "../../../components/common/table/ServerSideTableColumnHeader.styles";
import { ExpandedTableStyle, ModalImageContainer } from "../ActionModuleManagement.styles";
import { ModalComponent } from "../../../components/common/ModalComponent";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import { actionsApi } from "../../../api/actionsApi";
import { setProcessingRequest } from "../../../store/reducers/tableManagementReducer";
import { ActionModuleInstancesEditItem, FulfilledStatusType } from "../../../types/actionItem";
import FulfilledStatusIcon, { FulfilledIconWrapper } from "../../../components/common/table/FulfilledStatusIcon";
import { useActionEditMode } from "../../../store/selectors/actionsSelectors";
import { setEditedActionInstancesData } from "../../../store/reducers/actionsReducer";
import ActionModuleInstancesType from "../../../types/actionModuleInstancesType";

const ExpandedTable = (props) => {
    const dispatch = useAppDispatch();
    const [tableContent, setTableContent] = useState<ActionModuleInstancesType[]>([])

    useEffect(() => {
        const fetchUsers = async () => {
            setTableContent([]);
            dispatch(setProcessingRequest(true));
            const res = await actionsApi.getActionModuleInstances(
                props.rowData.actionModuleId,
                props.rowData.id
            );
            setTableContent(res.result ?? []);
            dispatch(setProcessingRequest(false));
        };
        fetchUsers()
    }, [props.rowData.id, props.rowData.actionModuleId]);

    // const dummyData = [
    //     {
    //         completedAt: "03.05.2024",
    //         attachmentFiles: ["https://picsum.photos/seed/picsum/200/300"],
    //         points: 3,
    //         fulfillmentStatus: "FullyFulfilled",
    //         actionModuleInstanceId: 11,
    //         score: 1
    //     },
    //     {
    //         completedAt: "07.02.2024",
    //         attachmentFiles: ["https://picsum.photos/seed/picsum/600/400"],
    //         points: 4,
    //         fulfillmentStatus: "FullyFulfilled",
    //         actionModuleInstanceId: 22,
    //         score: 2
    //     },
    //     {
    //         completedAt: "07.02.2024",
    //         attachmentFiles: ["https://picsum.photos/seed/picsum/600/400"],
    //         points: 4,
    //         fulfillmentStatus: "FullyFulfilled",
    //         actionModuleInstanceId: 33,
    //         score: 2
    //     }
    // ]

    return <ExpandedTableStyle>
        {tableContent[0] &&
            <Box gridTemplateColumns="3fr 3fr 2fr 2fr" display="grid">
                <ExpandedTableHeader />
                <Box sx={{ display: "contents" }}>
                    {
                        tableContent.map((u, i) =>
                            <ExpandedTableRow
                                key={i}
                                row={u}
                                employeeId={props.rowData.id}
                                actionModuleId={props.rowData.actionModuleId} />)
                    }
                </Box>
            </Box>
        }
    </ExpandedTableStyle>
}

function ExpandedTableHeader() {
    const { t } = useTranslation();

    return <TableHeaderWrapper>
        <TableColumnHeaderWrapper style={{
            ...({ paddingLeft: "25px" }),
            ...({ paddingRight: "25px" }),
        }}>
            <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>
                {t("tableColumnHeaders.lastChange")}
            </Typography>
        </TableColumnHeaderWrapper>
        <TableColumnHeaderWrapper>
            <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>
                {t("tableColumnHeaders.successfulUploads")}
            </Typography>
        </TableColumnHeaderWrapper>
        <TableColumnHeaderWrapper>
            <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>
                {t("tableColumnHeaders.notFulfilled")}
            </Typography>
        </TableColumnHeaderWrapper>
        <TableColumnHeaderWrapper>
            <Typography style={{ flex: 1, textOverflow: "ellipsis", whiteSpace: "nowrap" }} display={"inline-block"}>
                {t("tableColumnHeaders.fulfilled")}
            </Typography>
        </TableColumnHeaderWrapper>
    </TableHeaderWrapper>;
}

interface ActionModuleInstancesTypeRowProps {
    row: ActionModuleInstancesType,
    employeeId: string,
    actionModuleId: string
}

function ExpandedTableRow(props: ActionModuleInstancesTypeRowProps) {
    const dispatch = useAppDispatch();
    const [successModal, setSuccessModal] = useState<boolean>(false)
    const [fulfilledState, setFulfilledState] = useState<boolean>(props.row.fulfillmentStatus === FulfilledStatusType.Fulfilled)
    const actionEditMode = useActionEditMode();
    const closeModal = () => {
        setSuccessModal(false)
    }

    function handleFulfillmentStatusChange(newState: FulfilledStatusType) {
        setFulfilledState(newState === FulfilledStatusType.Fulfilled)
        let newInstance: ActionModuleInstancesEditItem = {
            actionModuleId: props.actionModuleId,
            employeeId: props.employeeId,
            actionModuleInstanceId: props.row.actionModuleInstanceId,
            fulfilledStatus: newState === FulfilledStatusType.Fulfilled ? FulfilledStatusType.Fulfilled : FulfilledStatusType.NotFulfilled
        }
        dispatch(setEditedActionInstancesData(newInstance))
    }

    return <>
        <BasicTableRowStyles>
            <CellBox firstCol={true}>
                <Typography>{props.row.completedAt?.substring(0, 10)}</Typography>
            </CellBox>
            <CellBox>
                <>
                    {props.row.attachmentFiles.length > 0 &&
                        props.row.attachmentFiles.map((fileUrl, i) => (
                            <Box
                                key={fileUrl}
                                onClick={() => { setSuccessModal(true) }}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer'
                                    }
                                }}
                            >
                                File {i + 1} / {props.row.attachmentFiles.length}
                            </Box>
                        ))
                    }
                </>
            </CellBox>
            <CellBox>
                <Button
                    disabled={!actionEditMode}
                    onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.NotFulfilled)}>
                    {!fulfilledState
                        ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.NotFulfilled} />
                        : <FulfilledIconWrapper />
                    }
                </Button>
            </CellBox>
            <CellBox lastCol={true}>
                <Button
                    disabled={!actionEditMode}
                    onClick={() => handleFulfillmentStatusChange(FulfilledStatusType.Fulfilled)}>
                    {fulfilledState
                        ? <FulfilledStatusIcon fulfilledStatus={FulfilledStatusType.Fulfilled} />
                        : <FulfilledIconWrapper />
                    }
                </Button>
            </CellBox>
        </BasicTableRowStyles>
        <ModalComponent isOpen={successModal} onClose={closeModal}>
            <ModalImageContainer>
                <img
                    key={Date.now()}
                    src={props.row.attachmentFiles[0]}
                    height="100%"
                    width="90%"
                    alt="workspacee"
                    style={{ objectFit: "contain", maxWidth: "90%", maxHeight: "90%" }} />
            </ModalImageContainer>
        </ModalComponent>
    </>
}

export default ExpandedTable