import { doApiGet } from "./Requests";

export const companyManagementApi = {
  getCompany: () =>
    doApiGet("/api/companies/current")
      .then(res => res.result)
      .catch(error => console.log(error)),

  getAllCompanies: () =>
    doApiGet("/api/companies")
      .then(res => res.result)
      .catch(error => console.log(error)),

  bulkDownloadOnboardingFiles: () =>
    doApiGet<Blob>("/api/companymanagementuser/onboardingfiles", true),
};
