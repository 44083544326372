import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionItem, ActionModuleInstancesEditItem } from "../../types/actionItem";
import { fetchActions } from "../thunk/actionsThunk";


export interface ActionsInitialState {
  actionsData: ActionItem[];
  actionToEdit: ActionItem | Partial<ActionItem> | null;
  actionInstancesToEdit: ActionModuleInstancesEditItem[] | Partial<ActionModuleInstancesEditItem>[] | []
  editMode: boolean
}

const initialState: ActionsInitialState = {
  actionsData: [],
  actionToEdit: null,
  actionInstancesToEdit: [],
  editMode: false
};

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setActions: () => { },
    setActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      if (state.actionToEdit)
        state.actionToEdit = { ...state.actionToEdit, ...action.payload };
      else state.actionToEdit = action.payload;
    },
    setActionEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setEditedActionData: (state, action: PayloadAction<Partial<ActionItem>>) => {
      state.actionToEdit = action.payload
    },
    setEditedActionInstancesData: (state, action: PayloadAction<Partial<ActionModuleInstancesEditItem>>) => {
      state.actionInstancesToEdit = state.actionInstancesToEdit.length === 0
        ? [action.payload]
        : [
          ...state.actionInstancesToEdit.filter(
            (item) => item.actionModuleInstanceId !== action.payload.actionModuleInstanceId
          ),
          action.payload,
        ];
    },
    deleteAction: (state, action: PayloadAction<number>) => {
      state.actionsData = state.actionsData.filter(
        (item) => item.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActions.fulfilled, (state, action) => {
      if (!action.payload || !(action.payload as ActionItem[]).length)
        state.actionsData = [];
      else state.actionsData = action.payload as ActionItem[];
    });
  },
});

export const {
  deleteAction,
  setActions,
  setActionData,
  setActionEditMode,
  setEditedActionData,
  setEditedActionInstancesData
} = actionsSlice.actions;

export default actionsSlice.reducer;
