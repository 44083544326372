import { Download } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { setProcessingRequest } from "../../store/reducers/tableManagementReducer";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { companyManagementApi } from "../../api/companyManagementApi";
import { theme } from "../../theme";
import { useTranslation } from "react-i18next";


export default function BulkDownloadOnboardingFilesButton() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleBulkDownloadOnboardingFiles = async () => {
        dispatch(setProcessingRequest(true))

        const result = await companyManagementApi.bulkDownloadOnboardingFiles();

        var file = window.URL.createObjectURL(result.result!);

        var a = document.createElement('a');
        a.href = file;
        a.download = "Onboarding_Documents.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();

        dispatch(setProcessingRequest(false))
    }

    return <Tooltip title={t("accountManagementPage.bulkDownloadOnboardingFiles")}>
        <IconButton onClick={handleBulkDownloadOnboardingFiles}>
            <Download sx={{ color: theme.palette.companyMain.main }} />
        </IconButton>
    </Tooltip>;
}