import { Box, styled } from "@mui/material";

export const ActionModuleWrapper = styled(Box)(() => ({
  marginBottom: "25px",
}));

export const SearchBarWrapper = styled(Box)(() => ({
  marginTop: "15px",
  marginBottom: "25px",
  marginLeft: "25px",
  marginRight: "25px",
  display: "flex",
  justifyContent: "space-between"
}));

export const ActionModuleManagementStyles = styled(Box)(({ theme }) => ({
  height: "auto",
  backgroundColor: theme.palette.backgroundColor.main,
}));

export const ExpandedTableStyle = styled(Box)(() => ({
  border: "1px solid lightgrey",
  borderRadius: "5px",
  margin: "1em"
}));

export const ImageContainer = styled(Box)(() => ({
  border: "1px solid lightgrey",
  borderRadius: "5px",
  objectFit: "contain",
  maxWidth: "100%",
  height: "100%",
  transition: "0.2s",
  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.1)"
  },
}));

export const ModalImageContainer = styled(Box)(() => ({
  display: "inline-block",
}));
