import { createAsyncThunk } from "@reduxjs/toolkit";
import { 
  ActionModulesDoneType, 
  ActivatedAccountsType, 
  AveragePointType, 
  ControlSignatureMonthlyType, 
  ControlSignatureWeeklyType, 
  EvaluationStarsType, 
  PresenceQuotaType, 
  TotalPointType 
} from "../../types/reportingTypes";
import { reportingApi } from "../../api/reportingApi";

export const fetchAveragePointThunk = createAsyncThunk(
  "reporting/averagePoints",
  async ({ month, take }: { month: number, take: number }) => {
    try {
      const res = await reportingApi.getAveragePoints(month, take);
      return res as AveragePointType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchTotalPointThunk = createAsyncThunk(
  "reporting/totalPoints",
  async ({ take }: { take: number }) => {
    try {
      const res = await reportingApi.getTotalPoints(take);
      return res as TotalPointType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchActionModulesDoneThunk = createAsyncThunk(
  "reporting/actionModulesDone",
  async ({ take }: { take: number }) => {
    try {
      const res = await reportingApi.getActionModulesDone(take);
      return res as ActionModulesDoneType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchPresenceQuotaThunk = createAsyncThunk(
  "reporting/presenceQuota",
  async ({ year, month }: { year: number, month: number }) => {
    try {
      const res = await reportingApi.getPresenceQuota(year, month);
      return res as PresenceQuotaType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchControlSignatureMonthlyThunk = createAsyncThunk(
  "reporting/controlSignatureMonthly",
  async ({ year, month }: { year: number, month: number }) => {
    try {
      const res = await reportingApi.getControlSignatureMonthly(year, month);
      return [res] as ControlSignatureMonthlyType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchControlSignatureWeeklyThunk = createAsyncThunk(
  "reporting/controlSignatureWeekly",
  async ({ year, month }: { year: number, month: number }) => {
    try {
      const res = await reportingApi.getControlSignatureWeekly(year, month);
      return res as ControlSignatureWeeklyType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchActivatedAccountsThunk = createAsyncThunk(
  "reporting/activatedAccounts",
  async () => {
    try {
      const res = await reportingApi.getActivatedAccounts();
      return [res] as ActivatedAccountsType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const fetchEvaluationStarsThunk = createAsyncThunk(
  "reporting/evaluationStars",
  async ({ startDate, endDate }: { startDate: string, endDate: string }) => {
    try {
      const res = await reportingApi.getEvaluationStars(startDate, endDate);
      return [res] as EvaluationStarsType[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);