import { Box, Button, Link, Popover, styled, Typography } from "@mui/material";
import { theme } from "../../theme";

export const ActionsBlockContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.backgroundColor?.main,
  border: `1px solid ${theme.palette.border?.main}`,
}));

export const ActionsBlockWrapper = styled(Box)(({ item }: { item: boolean }) => ({
  display: "flex",
  flexDirection: item ? "row" : "column",
  marginTop: "20px",
  "& > .MuiTypography-root": {
    lineHeight: "1",
    fontWeight: "700",
    margin: "0 25px 20px 39px",
  },
}));

export const ActionsGrid = styled(Box)({
  display: "grid",
  padding: " 0 25px 25px 39px",
  columnGap: "40px",
  rowGap: "40px",
  gridTemplateColumns: "repeat(auto-fit, minmax(450px, 1fr))",
  gridTemplateRow: "repeat(auto-fit, minmax(229px, 1fr))",
  gridAutoRows: "1fr",
  gridAutoFlow: "row",
  alignItems: "stretch",
  flexWrap: "wrap",
});

export const ActionOptionsStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  padding: "2rem",
  gap: "1rem",
  border: `1px solid ${theme.palette.border.main}`,
  backgroundColor: theme.palette.backgroundColor.main,
  transition: '0.3s',
  ".more-btn": {
    position: "absolute",
    top: "0rem",
    right: "0rem",
    cursor: "pointer",
    width: "40px",
    height: "40px",
    minWidth: "0px",
    margin: "0",
  },
}));

export const ActionsBlockDivider = styled(Box)(() => ({
  borderLeft: "1px solid lightgrey",
}));

export const ActionOptionsPopover = styled(Popover)(({ theme }) => ({
  ".MuiButtonBase-root": {
    width: "100%",
    padding: "8px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.palette.textMain.main,
    svg: {
      marginRight: "5px",
    },
  },
}));

export const ActioCardData = styled(Box)({
  width: "40%"
});

export const ActionCardHeaderStyles = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "11px",
  "& > .MuiBox-root": {
    "& > button": {
      padding: "0",
      border: "none",
      background: "none",
      pointerEvents: "none",
      cursor: "default",
      minWidth: "0",
      width: "fit-content",
      svg: {
        width: "36px",
        height: "auto",
      },
    },
  },
  "& > .MuiTypography-root": {
    marginLeft: "21px",
    a: { textDecoration: "none", color: "inherit" },
  },
  "&:hover": {
    color: theme.palette.companyMain.main,
    cursor: "pointer"
  },
});

export const ActionCardContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
});

export const ActionInfoWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "0.25rem",
  marginTop: "1rem"
});

export const ActionInfoStyles = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  svg: { width: "19px", height: "19px" },
});

export const MaxPointsStyles = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  justifyContent: "center",
  span: {
    color: theme.palette.textMain.secondary,
  },
}));

export const UploadDocMenu = styled(Box)(
  ({ uploadPopupIsActive }: { uploadPopupIsActive: boolean }) => ({
    maxWidth: "350px",
    minWidth: "150px",
    display: uploadPopupIsActive ? "block" : "none",
    boxSizing: "border-box",
    "& > .MuiBox-root": {
      height: "100%",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      "& > div > div": {
        "& > .MuiTypography-root": {
          fontSize: "0.8rem",
          marginLeft: "5px",
        },
      },
    },
    "& input": {
      width: "0",
      height: "0",
      visibility: "hidden",
      position: "absolute",
    },
  }));

export const UploadDocArea = styled(Box)(({ isFile }: { isFile: boolean }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "15px",
  width: "100%",
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.companyMain.main}`,
  cursor: "pointer",
  svg: {
    color: theme.palette.companyMain.main,
    width: "33px",
    height: "33px",
  },
  "& > .MuiTypography-root": {
    fontSize: "0.7rem",
    color: theme.palette.companyMain.main,
    fontWeight: "600",
    marginBottom: "5px",
  },
  "& > .MuiBox-root": {
    "& > .MuiBox-root": {
      boxSizing: "border-box",
      justifySelf: "flex-end",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      svg: {
        width: "15px",
        height: "15px",
      },
    },
  },
}));

export const DocActionBtnsBlock = styled(Box)(
  ({ isFile }: { isFile: boolean }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    button: {
      margin: "0 5px",
      "&:last-child": {
        svg: {
          path: {
            fill: isFile
              ? theme.palette.warningColor.main
              : theme.palette.textMain.secondary,
          },
        },
      },
      "&:first-child": {
        padding: 0,
        width: "fit-content",
        minWidth: "0",
        "&[disabled]": {
          color: theme.palette.textMain.secondary,
        },
      },
    },
  }));

export const ApplyDocBtn = styled(Button)(({ theme }) => ({
  "&[disabled]": {
    backgroundColor: theme.palette.textMain.secondary,
    border: `1px solid ${theme.palette.textMain.secondary}`,
  },
  padding: "4px 12px",
  color: theme.palette.backgroundColor.main,
  background: theme.palette.companyMain.main,
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.companyMain.main}`,
  "&:hover": {
    color: theme.palette.companyMain.main,
  },
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  margin: "0 auto"
}));

export const FileHandlingWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "start",
  gap: "1rem"
});

export const ExcelUpload = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
});

export const TemplateDownload = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
});

export const ExcelLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  '&:hover': {
    color: theme.palette.companyMain.main
  }
}));

export const ActionEditComponent = styled(Box)({
  padding: "20px",
  display: "block",
  border: "1px solid black",
  textAlign: "center",
  position: "fixed",
  height: "85%",
  width: "40%",
  right: "3em",
  top: "4em",
  backgroundColor: "white"
});

export const ActionModuleSingleData = styled(Box)({
  marginTop: "1em",
  textAlign: "left",
  paddingLeft: "5px"
});

export const ActionModuleDataHeader = styled(Typography)({
  fontWeight: "700",
});

export const MaxUserWrapper = styled(Box)({
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center"
});
