import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ActionItem } from "../../types/actionItem";
import { actionsApi } from "../../api/actionsApi";
import { ActionModuleIncentivationOverview } from "./overview/ActionModuleIncentivationOverview";
import PageTitle from "../../components/common/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { Box, Card, Tab, Tabs } from "@mui/material";
import ActionCard from "../actionModules/ActionsBlock/ActionCard/ActionCard";
import ActionModuleIncentivation from "./ActionModuleIncentivation";
import { CircularIndeterminate } from "../../components/common/CircularIndeterminate";
import { ActionModuleWrapper } from "./ActionModuleManagement.styles";
import SubtypeOverviewCard from './overview/SubtypeOverviewCard';
import { useProcessingRequest } from "../../store/selectors/tableManagementSelector";
import { useAppDispatch } from "../../hooks/StoreHooks";
import { setProcessingRequest } from "../../store/reducers/tableManagementReducer";


const ActionModuleManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { subtype, actionModuleId } = useParams();

  const isOverview = !actionModuleId;

  const location = useLocation();
  const baseLocation = location.pathname.split("/").slice(0, 4).join("/");

  const [actionModule, setActionModule] = useState<ActionItem | null>(null);
  const [actionModules, setActionModules] = useState<ActionItem[]>([]);
  const processingRequest = useProcessingRequest();

  const showScore = actionModules.filter((a) => a.type.includes("ExcelIncentive")).length > 0;

  useEffect(() => {
    const fetchActionModules = async () => {
      if (!subtype) return;
      setActionModules([]);
      dispatch(setProcessingRequest(true));
      const res = await actionsApi.getActionModulesForSubtype(subtype);
      dispatch(setProcessingRequest(false));
      setActionModules(res?.result ?? []);
    };
    fetchActionModules();
  }, [subtype]);

  useEffect(() => {
    setActionModule(actionModules.filter((a) => a.id?.toString() === actionModuleId)[0] ?? null);
  }, [actionModuleId, actionModules]);

  const breadcrumbs = [
    {
      title: "incentivationPage.taxFreeBenefit",
      link: "incentivation/taxfreebenefit",
    },
    ...actionModule ? [{
      title: actionModule.name,
      link: `incentivation/taxfreebenefit/${subtype}/${actionModuleId}`,
    }] : [],
  ];

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("incentivationPage.title")}
      />

      {isOverview && subtype && <ActionModuleWrapper>
        <SubtypeOverviewCard subtype={subtype} showScore={showScore} />
      </ActionModuleWrapper>}

      {!isOverview && actionModule && <ActionModuleWrapper>
        <ActionCard actionItem={actionModule} showScore={showScore} showFileUpload={false} />
      </ActionModuleWrapper>}

      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={actionModule}>
            <Tab label="Overview" value={null} component={Link} to={`${baseLocation}/${subtype}`} />
            {actionModules.map(e => <Tab key={e.id} label={e.name} value={e} component={Link} to={`${baseLocation}/${subtype}/${e.id}`} />)}
          </Tabs>
        </Box>

        {isOverview && subtype && <ActionModuleIncentivationOverview actionModuleSubtype={subtype} />}
        {!isOverview && actionModule && <ActionModuleIncentivation actionModule={actionModule} />}
      </Card>

      <CircularIndeterminate processingRequest={processingRequest} />
    </>
  );
};

export default ActionModuleManagement;
