import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { PaginationParameters } from "../../../api/Pagination";
import { BasicTableRowStyles, TableHeaderWrapper } from "../../../components/common/Common.styles";
import { useTranslation } from "react-i18next";
import { TableSortDirection } from "../../../common/logic/TableSortUtils";
import BorderRow from "../../../components/common/table/BorderRow";
import CellBox from "../../../components/common/table/CellBox";
import StatusDisplay from "../../../components/common/table/StatusDisplay";
import { ServerSidePagination } from "../../../components/common/table/ServerSidePagination";
import EmployeeIncentivationType from "../../../types/employeeIncentivationType";
import ServerSideTableColumnHeader, { SortDelegate } from "../../../components/common/table/ServerSideTableColumnHeader";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandedTable from "./ExpandedTable";

interface Props {
    pagination: PaginationParameters;
    totalCount: number;
    onUpdatePagination: (newValue: PaginationParameters) => void;
    employees: EmployeeIncentivationType[];
}

export default function IncentivationOverviewTable(props: Props) {
    const { t } = useTranslation();

    function onTableSortUpdate(direction: TableSortDirection | null, field: string | null) {
        props.onUpdatePagination({
            ...props.pagination,
            order: direction ?? undefined,
            sort: field ?? undefined,
        });
    }

    const pictureUploadModule = window.location.href.substring(window.location.href.lastIndexOf('/') + 1) === "pictureUpload"
    const extraColumn = pictureUploadModule ? t("tableColumnHeaders.successfulUploads") : undefined

    return <Box>
        <IncentivationOverviewTableHeader
            onTableSort={onTableSortUpdate}
            sortDirection={props.pagination.order}
            sortFieldName={props.pagination.sort}
            expandable={pictureUploadModule}
            extraColumn={extraColumn} />
        <Box sx={{ display: "contents" }}>
            {
                props.employees.map((u) =>
                    <IncentivationOverviewTableRow
                        key={u.id}
                        row={u}
                        expandable={pictureUploadModule}
                        extraColumn={extraColumn}
                    />)
            }
        </Box>
        <ServerSidePagination
            totalCount={props.totalCount}
            pagination={props.pagination}
            onUpdatePagination={props.onUpdatePagination} />
    </Box >
}

interface IncentivationOverviewTableHeaderProps {
    sortDirection: TableSortDirection | undefined;
    sortFieldName: string | undefined;
    onTableSort: SortDelegate;
    expandable?: boolean
    extraColumn?: string
}

function IncentivationOverviewTableHeader(props: IncentivationOverviewTableHeaderProps) {
    const { t } = useTranslation();
    const gridTemplate = `${props.expandable ? `1fr` : "0fr"} 3fr 2fr 2fr ${props.extraColumn ? `1fr` : ""} 1fr 1fr`
    return <Box gridTemplateColumns={gridTemplate} display="grid">
        <TableHeaderWrapper>
            <ServerSideTableColumnHeader
                fieldName="Exp"
                label=""
                firstCol={true}
            />
            <ServerSideTableColumnHeader
                fieldName="Name"
                isSorted={true}
                label={t("tableColumnHeaders.name")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
                firstCol={true}
            />
            <ServerSideTableColumnHeader
                fieldName="Position"
                isSorted={true}
                label={t("tableColumnHeaders.position")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
            />
            <ServerSideTableColumnHeader
                fieldName="EmployeeNumber"
                isSorted={true}
                label={t("tableColumnHeaders.employeeNo")}
                onTableSort={props.onTableSort}
                sortDirection={props.sortDirection}
                sortFieldName={props.sortFieldName}
            />
            {props.extraColumn &&
                <ServerSideTableColumnHeader
                    fieldName="successfulUploads"
                    label={props.extraColumn}
                />
            }
            <ServerSideTableColumnHeader
                fieldName="points"
                label={t("tableColumnHeaders.points")}
            />
            <ServerSideTableColumnHeader
                fieldName="status"
                label={t("tableColumnHeaders.status")}
                lastCol={true}
            />
            <BorderRow />
        </TableHeaderWrapper>
    </Box>
}

interface IncentivationOverviewTableRowProps {
    row: EmployeeIncentivationType,
    expandable?: boolean,
    extraColumn?: string
}

function IncentivationOverviewTableRow(props: IncentivationOverviewTableRowProps) {
    const [open, setOpen] = useState(false);
    const gridTemplate = `${props.expandable ? `1fr` : "0fr"} 3fr 2fr 2fr ${props.extraColumn ? `1fr` : ""} 1fr 1fr`
    return <>
        <Box gridTemplateColumns={gridTemplate} display="grid">
            <BasicTableRowStyles>
                {props.expandable
                    ?
                    <CellBox firstCol={true}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </CellBox>
                    :
                    <CellBox>
                        <Typography></Typography>
                    </CellBox>
                }
                <CellBox firstCol={true}>
                    <Typography>{props.row.name}</Typography>
                </CellBox>
                <CellBox>
                    <Typography>{props.row.position}</Typography>
                </CellBox>
                <CellBox>
                    <Typography>{props.row.employeeNumber}</Typography>
                </CellBox>
                {props.extraColumn &&
                    <CellBox>
                        <Typography>{props.row.successfulUploadedInstances}/{props.row.totalInstances}</Typography>
                    </CellBox>
                }
                <CellBox>
                    <Typography>{props.row.points}</Typography>
                </CellBox>
                <CellBox lastCol={true}>
                    <StatusDisplay status={props.row.status} />
                </CellBox>
            </BasicTableRowStyles>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <ExpandedTable rowData={props.row} />
        </Collapse>
    </>
}