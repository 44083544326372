import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../../components/common/PageTitle/PageTitle";
import { useAppDispatch } from "../../../hooks/StoreHooks";
import CertificatesBlock from "./CertificatesBlock";
import { fetchCertificatesThunk } from "../../../store/thunk/certificatesThunk";
import { setProcessingRequest, setResponseValue } from "../../../store/reducers/tableManagementReducer";
import { useProcessingRequest, useResponseMessage, useResponseValue } from "../../../store/selectors/tableManagementSelector";
import { CircularIndeterminate } from "../../../components/common/CircularIndeterminate";
import { ModalComponent } from "../../../components/common/ModalComponent";
import { Box } from "@mui/material";

const breadcrumbs = [
    {
      title: "documentManagementPage.documentManagement.title",
      link: "",
    },
    {
      title: "documentManagementPage.documentManagement.certificatesTrainings.title",
      link: "documentManagement/certificatesTrainings",
    },
  ];
  
const Certificates = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const processingRequest = useProcessingRequest()
  const responseValue = useResponseValue()
  const responseMessage = useResponseMessage()

  const [successModal, setSuccessModal] = useState<boolean>(false)

  useEffect(() => {
    if (!processingRequest) {
      dispatch(setProcessingRequest(true))
    }
    dispatch(fetchCertificatesThunk());
  }, []);

  useEffect(() => {
    if (processingRequest === false && responseValue.includes("fulfilled")) {
      setSuccessModal(true)
      dispatch(setResponseValue(""))
    }
  }, [processingRequest]);

  const closeModal = () => {
    setSuccessModal(false)
  }

  return (
    <>
      <PageTitle
        breadcrumbs={breadcrumbs}
        title={t("documentManagementPage.documentManagement.title")}
      />
      <CertificatesBlock />

      <CircularIndeterminate processingRequest={processingRequest} />
      <ModalComponent isOpen={successModal} onClose={closeModal}>
        <Box>{responseMessage}</Box>
      </ModalComponent>
    </>
  );
};

export default Certificates;
