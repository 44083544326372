import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setAccountManagementEditMode } from "../reducers/accountManagementReducer";
import { employeesApi } from "../../api/employeesApi";
import { EmployeeGeneral } from "../../types/employeeType";
import { StatusTypes } from "../../types/common";
import { AccountManagementUserType } from "../../types/accountManagementUsersType";
import { setProcessingRequest } from "../reducers/tableManagementReducer";
import dayjs from "dayjs";

export const fetchAccountManagementUsersThunk = createAsyncThunk(
  "accountManagement/fetchUsers",
  async (_, thunkApi) => {
    try {
      const employees = await employeesApi.getAccountManagementEmployees();
      thunkApi.dispatch(setProcessingRequest(false))
      return employees as EmployeeGeneral[];
    } catch (error) {
      console.log(error);
    }
    return [];
  }
);

export const createNewAccountManagementUserThunk = createAsyncThunk(
  "accountManagement/createUser",
  async (user: Partial<AccountManagementUserType>, thunkApi) => {
    const newUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.birthDate,
      employmentDate: user.employmentDate,
      tachographNumber: "",
      employeeNumber: user.employeeNumber,
      position: user.position,
      status: user.status ? user.status : StatusTypes.Blocked,
      groupId: user.groupId && user.groupId > 0 ? user.groupId : null,
      groupName: user.group?.name
    };

    try {
      await employeesApi.createEmployee([newUser]);
      await thunkApi.dispatch(fetchAccountManagementUsersThunk());
    } catch (error) {
      console.log("error");
    } finally {
      thunkApi.dispatch(setProcessingRequest(false))
    }
    return null;
  }
);

export const deleteAccountManagementUserThunk = createAsyncThunk(
  "accountManagement/deleteUser",
  async (userId: string, thunkApi) => {
    try {
      await employeesApi.deleteEmployee(userId);
    } catch (error) {
      console.log(error);
    } finally {
      thunkApi.dispatch(setProcessingRequest(false))
    }
    return userId;
  }
);

export const editAccountManagementUsersThunk = createAsyncThunk(
  "accountManagement/editUsers",
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const usersDataToEdit = state.accountManagement.usersToEdit

    const usersDataToEditRequests = usersDataToEdit.map((user) =>
      employeesApi.editAccountManagementEmployee(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        birthDate: user.birthDate,
        employmentDate: user.employmentDate,
        position: user.position,
        employeeNumber: user.employeeNumber,
        tachographNumber: user.tachographNumber,
        status: user.status,
        groupId: user.groupId > 0 ? user.groupId : null,
        groupName: user.groupName
      })
    );

    Promise.all(usersDataToEditRequests)
      .then(async (res) => {
        await thunkApi.dispatch(fetchAccountManagementUsersThunk());
        thunkApi.dispatch(setAccountManagementEditMode(false));
        thunkApi.dispatch(setProcessingRequest(false))
        return res
      })
      .catch(error => console.log(error))

    return state.accountManagement.usersToEdit;
  }
);

export const uploadAccountManagementUsersThunk = createAsyncThunk(
  "accountManagement/uploadEmployees",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const data = state.accountManagement.excelUploadEmployeesParsedData;
      let employeeUsers: Partial<AccountManagementUserType>[] = [];
      Object.keys(data).forEach(e => {
        employeeUsers.push({
          firstName: data[e]["First name"].toString(),
          lastName: data[e]["Last name"].toString(),
          birthDate: data[e]["Birth date"] != null ? dayjs(data[e]["Birth date"], "DD.MM.YYYY").format("YYYY-MM-DD") : null,
          employmentDate: data[e]["Employment date"] != null ? dayjs(data[e]["Employment date"], "DD.MM.YYYY").format("YYYY-MM-DD") : null,
          position: data[e].Position.toString(),
          employeeNumber: data[e]["Employee No."].toString(),
          groupName: data[e].Group?.toString(),
          status: data[e].Status.toString(),
          tachographNumber: "",
        });
      });
      await employeesApi.createEmployee(employeeUsers);
      thunkApi.dispatch(fetchAccountManagementUsersThunk());
    } catch (error) {
      console.log(error);
    }
  }
);